<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link to="/support" class="btn btn-sm btn-primary mb-3">
          Back
        </router-link>
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">View Detail</h4>
          </div>
          <div class="card-body">
            <h6>User Detail</h6>
            <p  class="m-0">
              Name: {{ data?.user_profile?.full_name || data?.name || "" }}
            </p>
            <p v-if="data.user_profile" class="m-0">
              CNIC: {{ data?.user_profile?.user?.cnic }}
            </p>
            <p v-if="data.user_profile" class="m-0">
              Phone: {{ data?.user_profile?.user?.phone }}
            </p>
            <p  class="m-0">
              Email: {{ data?.user_profile?.user?.email || data?.email || " " }}
            </p>
            <hr />
            <h6>Message Detail</h6>
            {{ data.detail }}
            <img
              v-if="data.media"
              :src="data.media.file_url"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.fetch(id);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("support/getSingle", { id })
        .then((response) => {
          this.data = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
